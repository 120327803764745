<template>
  <div
    class="slider-arrows"
    :class="{ small: size === 'small', big: size === 'big', [color]: true }"
  >
    <div
      v-if="!which || which === 'left'"
      class="left"
      :class="{ disabled: modelValue?.isBeginning }"
    >
      <Icon class="icon" name="arrow-left" @click="shift('left')" />
    </div>
    <slot />
    <div
      v-if="!which || which === 'right'"
      class="right"
      :class="{ disabled: !modelValue?.params.loop && modelValue?.isEnd }"
    >
      <Icon class="icon" name="arrow-right" @click="shift('right')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { SwiperBinding } from '@/lib/types/models/swiper';

withDefaults(
  defineProps<{
    size?: 'big' | 'small';
    color?: 'white' | 'black' | 'transparent';
    which?: 'left' | 'right';
    wholeGroup?: boolean;
    isLoop?: boolean;
  }>(),
  {
    size: 'big',
    color: 'transparent',
    which: undefined
  }
);

const model = defineModel<SwiperBinding>({});

function shift(direction: 'left' | 'right'): void {
  if (!model.value) {
    return;
  }

  if (direction === 'left') {
    model.value.slidePrev();
  } else {
    model.value.slideNext();
  }
}
</script>

<style lang="scss" scoped>
@use '$/colors.scss';
@use '$/mouse-behavior.scss';
@use '$/shadows.scss';
@use '$/z-index.scss';
@use '$/breakpoints.scss';

.slider-arrows {
  gap: 40px;

  .left,
  .right {
    @include mouse-behavior.clickable-opacity($disabled-diff: -1);

    .icon:deep(svg) {
      width: 32px;
      height: 32px;
    }
  }

  &.small {
    .left,
    .right {
      @include mouse-behavior.clickable-opacity($disabled-diff: -1);

      .icon:deep(svg) {
        width: 24px;
        height: 24px;
      }
    }
  }

  &.group-centered {
    display: contents;

    .left,
    .right {
      position: absolute;
      z-index: z-index.$any-on-top;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  &.black,
  &.white {
    .left,
    .right {
      border-radius: 50%;
      box-shadow: shadows.$bubble-map;
    }
  }

  &.black:deep(svg) {
    circle {
      fill: colors.$black;
    }

    path {
      fill: white;
    }
  }

  &.transparent {
    .left,
    .right {
      &.disabled {
        opacity: 0.25;
      }
    }

    :deep(svg) {
      circle {
        fill: none;
      }
    }
  }
}

@include breakpoints.mobile() {
  .slider-arrows {
    .left,
    .right {
      .icon:deep(svg) {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
